.sideMenu {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: fit-content;
  border-radius: 20px;
  position: relative;
  min-width: 300px;
  max-width: 300px;
  .exitBtn {
    display: none;
  }
  img {
    width: 120px;
    height: 90px;
    object-fit: contain;
    margin: 20px auto;
  }
  small {
    padding-left: 30px;
    font-size: 12px !important;
    color: var(--greyColor700);
  }
  .content {
    margin-top: 10px;
    padding: 10px;
    .unActive {
      display: flex;
      cursor: pointer;
      gap: 10px;
      margin-bottom: 10px;
      justify-content: flex-start;
      align-items: center !important;
      background-color: #fff;
      padding: 14px 10px;
      padding-left: 20px;
      border-radius: 10px;
      p,
      svg {
        color: var(--greyColor800);
        margin-bottom: 0 !important;
      }
      svg{
        font-size: 20px;
      }
    }
    .active {
      display: flex;
      cursor: pointer;
      gap: 10px;
      margin-bottom: 10px;
      justify-content: flex-start;
      align-items: center;
      background-color: #000;
      padding: 14px 10px;
      padding-left: 20px;
      color: #fff;
      border-radius: 10px;
      p,
      svg {
        color: #fff;
        margin-bottom: 0 !important;
        font-weight: 600;
      }
      svg{
        font-size: 20px;
      }
    }
  }
  .upgrade {
    padding: 1rem;
    padding-top: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: #000;
    margin: 1rem;
    margin-top: auto;
    border-radius: 25px;
    position: relative;
    img {
      position: absolute;
      top: -60px;
      width: 60px;
    }
    p {
      text-align: center;
      color: var(--greyColor400);
      font-size: 12px;
    }
    button {
      width: 100%;
      background-color: var(--whiteColor);
      color: var(--primaryColor);
      font-weight: 700;
      font-size: 12px;
    }
  }
}

@media (max-width: 1500px) and (min-width: 1000px) {
  .sideMenu {
    small {
      font-size: 10px;
      color: var(--greyColor800);
    }
    .content {
      margin-top: 0px;
      .unActive,
      .active {
        font-size: 13px;
      }
    }
    .upgrade {
      padding: 1rem;
      img {
        width: 30px;
      }
      h5 {
        font-size: 1rem;
        text-align: center;
        padding-top: 1rem;
      }
      p {
        font-size: 12px;
        margin-top: 10px;
      }
      button {
        padding: 10px 5px;
        font-size: 12px;
      }
    }
  }
}
@media (max-width: 1000px) {
  .sideMenu {
    .exitBtn {
      all: unset;
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
      background-color: var(--primaryColor);
      color: var(--whiteColor);
      width: fit-content;
      padding: 7px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
    }
  }
}
@media (max-width: 400px) {
  .sideMenu {
    min-width: auto;
   
  }
}
