.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 357px;
    .wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;

        textarea {
            width: 100%;
            align-self: center;
            height: 15vh;
        }

        label {
            left: 0.6rem;
        }
    }
}
