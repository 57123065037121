.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 1rem;

    div {
        p {
            font-size: 14px;
            font-weight: 500;
            color: var(--darkGreyColor)
        }
    }

    .input {
        width: 80%;
        border-radius: 4px;
        button {
            background-color: var(--secondaryColor);
            border-radius: 30px;
            border: none;
            padding: 6px 12px;
            color: var(--primaryColor);
            width: 100px;
            height: 35px;
        }
    }

    .promoInfo {
        display: flex;
        justify-content: space-between;
        width: 80%;
        p {
            font-size: 14px;
            color: var(--primaryColor);
            margin: 0 !important;
           
        }
    }

}