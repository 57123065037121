.button {
    background-color:  transparent !important;
    color: var(--primaryColor) !important;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 2px solid var(--primaryColor) !important;
    width: 100%;
    padding: 10px 30px;
    border-radius: 0.375rem;
    font-weight: 700;
    border: 2px solid var(--primaryColor);
    border-radius: 50px;

    &:hover {
        background-color: var(--primaryColor) !important;
        color: #fff !important;
        a{
            color: #fff !important;
        }
    }
    &:disabled {
        border-color: rgb(153, 153, 153) !important;
        background-color: rgb(153, 153, 153) !important;
        color: #fff !important;
        cursor: not-allowed;
    }
}