@import url('https://fonts.googleapis.com/css2?family=Cherry+Bomb+One&family=DM+Sans:opsz,wght@9..40,400;9..40,700&family=Inter:wght@400;700&family=Pacifico&family=Buenard&family=Playfair+Display&family=Poppins:wght@400;700&family=Roboto:wght@400;700&display=swap');

// fonts
@font-face {
  font-family: 'Kiona_Regular';
  src: url('./assets/fonts/kiona_regular.ttf');
}

@font-face {
  font-family: 'Pacifico_Regular';
  src: url('./assets/fonts/pacifico_regular.ttf');
}

@font-face {
  font-family: 'Roboto_Regular';
  src: url('./assets/fonts/roboto_regular.ttf');
}

@font-face {
  font-family: 'Inter_Regular';
  src: url('./assets/fonts/inter_regular.ttf');
}

@font-face {
  font-family: 'SegoeUI';
  src: url('./assets/fonts/segoe_ui.ttf');
}


@font-face {
  font-family: 'SansSerif';
  src: url('./assets/fonts/sans_serif.ttf');
}


@font-face {
  font-family: 'DMSa_Regular';
  src: url('./assets/fonts/dmsans_regular.ttf');
}

@font-face {
  font-family: 'CherryBombOne_Regular';
  src: url('./assets/fonts/cherry_bomb_one_regular.ttf');
}

@font-face {
  font-family: 'Buenard_Regular';
  src: url('./assets/fonts/buenard_regular.ttf');
}

@font-face {
  font-family: 'PlayfairDisplay_Regular';
  src: url('./assets/fonts/playfair_displayt.ttf');
}

@font-face {
  font-family: 'AppleGaramond';
  src: url('./assets/fonts/apple_garamond.ttf');
}

@font-face {
  font-family: 'MoskNormal400';
  src: url('./assets/fonts/mosk.ttf');
}

@font-face {
  font-family: 'LeytonPersonal_Bold';
  src: url('./assets/fonts/leyton_bold.otf');
}

@font-face {
  font-family: 'TrajanPro_Regular';
  src: url('./assets/fonts/trajan_pro_regular.otf');
}

@font-face {
  font-family: 'Riviera_Regular';
  src: url('./assets/fonts/riviera.otf');
}

@font-face {
  font-family: 'BlackCaviarALT_Regular';
  src: url('./assets/fonts/black_caviar.ttf');
}

@font-face {
  font-family: 'MyriadPro_Regular';
  src: url('./assets/fonts/myriad_pro_regular.ttf');
}

@font-face {
  font-family: 'Futura_Medium';
  src: url('./assets/fonts/futur.ttf');
}

@font-face {
  font-family: 'Baskervville_Regular';
  src: url('./assets/fonts/baskervville_regular.ttf');
}

@font-face {
  font-family: 'Helvetica';
  src: url('./assets/fonts/helvetica.ttf');
}

@font-face {
  font-family: 'TimesNewRomanPSMT';
  src: url('./assets/fonts/times_new_roman.ttf');
}

@font-face {
  font-family: 'Poppins_Regular';
  src: url('./assets/fonts/poppins_regular.ttf');
}


body {
  margin: 0;

  p,
  span,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  label,
  button {
    font-family: "Poppins";
  }

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F0F0F0 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sc-fLBbxL {
  overflow-x: auto;
  width: 100%;

}

.sc-hHvkSs {
  width: fit-content;
  min-width: 100%;

}

:root {
  --primaryColor: #000;
  --secondaryColor: #FEEE00;
  --darkYellow: #FEDB16;
  --lightColor: #FFF9E8;
  --yellowColor: #FFFDDD;
  --whiteColor: #fff;
  --greyColor: #F0F0F0;
  --errorColor: #FF0000;
  --errorColor700: #FF3232;
  --greyColor800: #828282;
  --greyColor700: #545454;
  --greyColor600: #EFEDED;
  --greyColor400: #d9d9d9;
  --greyColor200: #949DB2;
  --greyColor500: #DDDDDD;
  --greyColor300: #ECF0F2;
  --greyColor100: #DBDBDB;
  --darkGreyColor: #828282;
  --successColor: #008000;
  --greenColor: #E7FFE5;
  --redColor: #FFECEC;
  --blueDarkColor: #4E4E65;
  --lightBlueColor: #EAF5FF;
  --blueColor: #4EB5FF;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px !important;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: black;
  border-radius: 10px;
}


.ctgXpK {
  overflow-x: hidden !important;
}

.fWlzdU {
  overflow-y: auto !important;
  max-height: 50vh !important;

}

.celVQH {
  overflow-y: auto !important;
  max-height: 50vh !important;

}

.modal-header {
  border-bottom: 0 !important;
  margin: 0 !important;
}

.modal-content {
  border-radius: 25px !important;
}

.modal-body {
  padding: 0rem 3rem !important;
  padding-bottom: 3rem !important;
}

// .MuiMenu-paper{
//   max-height: 30vh !important;
//   overflow-y: auto !important;
// }

.css-106c1u2-MuiBadge-badge {
  background-color: var(--errorColor) !important;
}
