.button {
    background-color: var(--primaryColor);
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 2px solid var(--primaryColor);
    padding: 10px 30px;
    border-radius: 50px;
    font-weight: 500;
    // margin-top: 15px;
    &:hover {
        background-color: #fff;
        color: var(--primaryColor);
    }
    &:disabled {
        background-color: rgb(153, 153, 153);
        border-color: rgb(153, 153, 153);
        color: #fff;
        cursor: not-allowed;
    }
}