.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;

    img{
        width: 100px;
        height: 100px;
    }

    p {
        margin: 1rem 0rem;
        color: var(--primaryColor);
        font-weight: 500;
        text-align: center;
    }

    .actionBtns {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 4% 0;
        gap: 35px;

        div {
            width: 50%;
            button {
                padding: 6%;
                width: 100%;
            }
        }
    }
}
