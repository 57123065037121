.mainLayout {
    display: flex;
    overflow: none;
    gap: 20px;
    padding: 20px;
    background-color: var(--greyColor);

    .sideMenu{
        width: 300px;
    }
    .rightSide{
        width: 100%;
        height: 100%;
        overflow: hidden;
        .header {
          height: 70px;
        }
        .main{
          margin-top: 20px;
            background-color: #fff;
            border-radius: 20px;
            height: calc(100vh - 130px);
            overflow: auto;
        }
    }
}

@media (max-width: 1100px) {
    .mainLayout {
      .sideMenu {
        display: none;
      }
      .rightSide {
        width: 100%;
      }
    }
  }
  