.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.5rem;
    width: 100%;

    .havePromoCode {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding: 15px 10px;
        border-radius: 13px;
        background-color: var(--primaryColor);
        color: var(--whiteColor);
        cursor: pointer;
        .icon {
            width: 31px;
            height: 31px;

        }

        h3 {
            font-weight: 600;
            font-size: 14px
        }

        p {
            font-weight: 400;
            font-size: 12px;
            color: var(--greyColor400)
        }

        .content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: start;

            p {
                padding: 0;
                margin: 0;
            }
        }

        div {
            cursor: pointer;
            svg {
                font-size: 24px !important;
            }
        }



    }

    .actions {
        background-color: #EEE;
        display: flex;
        justify-content: space-between;
        border-radius: 25px;
        padding: 5px;
        gap: 1rem;

        .btn {
            background-color: #EEE !important;
            padding: 8px 12px;
            border-radius: 25px;
            width: 100%;
            border: none;
        }

    }

    .promoCodesContainer {
        width: 100%;
        height: 240px;
        min-height: calc(50vh - 230px);
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 0.4em;
            height: 100vh;
            cursor: pointer;
        }

        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 2px grey;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: var(--primaryColor);
            border-radius: 3px;
            cursor: pointer;

            &:hover {
                background-color: var(--primaryColor700);
                cursor: pointer;
            }
        }

        .promoItem {
            display: flex;
            align-items: start;
            margin-top: 1rem;

            img {
                width: 30px;
                height: 35px;
                object-fit: contain;
            }

            .promoCodeValue {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                margin-left: .5rem;
               // border-bottom: 1px solid #D9D9D9;
                margin-top: -5px;
                width: 80%;

                .title {
                    font-size: 1rem;
                    font-weight: 700;
                }

                .status {
                    font-size: .8rem;
                    font-weight: 400;
                    color: var(--greyColor400);
                    color: var(--darkGreyColor);
                    margin-bottom: 1rem;
                }
            }
        }
        .nodata{
            height: 200px;
            display: flex;
            justify-content: center;
            align-items: center;
            p{
                font-weight: 600;
                font-size: 18px;
                color: var(--greyColor700)
            }
        }
    }

}